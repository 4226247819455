<template>
  <section id="interactivities">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('interactivities.title') }}</h1>
        <h5 class="text-primary">
          {{ $t('interactivities.view') }}
        </h5>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-around m-2">
      <b-button @click="editInteractivitie()">
        <feather-icon
          icon="Edit2Icon"
          size="18"
        /> {{ $t('dataGeneric.edit') }}
      </b-button>
      <b-button @click="goToBackPage">
        {{ $t('dataGeneric.cancel') }}
      </b-button>
    </b-row>
    <!-- DATOS BÁSICOS -->
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-body class="m-1">
            <b-row class="infoContent mb-4">
              <b-col
                v-if="image"
                cols="12"
                md="5"
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  mb-2 mb-md-0
                "
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-img
                    :src="image"
                    fluid
                  />
                </div>
              </b-col>
              <!-- Right: Details -->
              <b-col
                cols="12"
                md="7"
              >
                <!-- Title -->
                <h3 class="mb-2">
                  {{ name }}
                </h3>
                <!-- Description -->
                <div class="mb-2 mb-sm-2">
                  <b-card-text>{{ description }}</b-card-text>
                </div>
                <div class="mb-1 mb-sm-3">
                  <span class="text-muted mr-75">{{ $t('editContent.status') }}</span>
                  <span
                    v-if="isActive"
                    class="text-success"
                  >{{ $t('activada') }} </span>
                  <span
                    v-else
                    class="text-danger"
                  >{{ $t('desactivada') }} </span>
                </div>
                <div
                  v-if="date"
                  class="mb-1 mb-sm-3"
                >
                  <span class="text-muted mr-75">{{ $t('message.tableHeader.date') }}</span>
                  <span>{{ date }} {{ hour }} </span>
                </div>
                <div v-else>
                  <span class="text-muted mr-75">{{ $t('contents.start') }}</span>
                  <span>{{ second }} 's</span>
                </div>
                <div>
                  <span class="text-muted mr-75">{{ $t('durInter') }}</span>
                  <span>{{ time }} 's</span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- RESOURCESTART -->
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-body class="m-1">
            <b-row class="d-flex align-items-center justify-content-center m-1">
              <h4>
                <strong> {{ resourceName }}</strong>
              </h4>
            </b-row>
            <b-row
              class="
                d-flex
                align-items-center
                justify-content-center
                infoContent
                mb-4
              "
            >
              <b-col
                cols="12"
                md="7"
              >
                <audio
                  v-if="videotype == 'AUR'"
                  :key="resourceId"
                  controls
                >
                  <source
                    v-if="directUrl != ''"
                    :src="directUrl"
                    type="audio/mp3"
                  >
                  <source
                    v-else
                    :src="bucketUrl"
                    type="audio/mp3"
                  >
                </audio>
                <video
                  v-else
                  :key="resourceId"
                  width="500"
                  height="305"
                  controls
                >
                  <source
                    v-if="directUrl != ''"
                    :src="directUrl"
                    type="video/mp4"
                  >
                  <source
                    :src="bucketUrl"
                    type="video/mp4"
                  >
                </video>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- RESOURCEEND EMAIL PUSH -->
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-body class="m-1">
            <b-row class="d-flex align-items-center justify-content-center m-1">
              <b-col v-if="connect == 'EMA'">
                <h4 class="mb-2">
                  {{ $t('Email') }}
                </h4>
                <b-row class="m-1">
                  <b-col>
                    <span class="text-muted mr-75">{{ $t('interactivities.asunto') }}</span>
                  </b-col>
                  <b-col>
                    <span>{{ subjectMail }}</span>
                  </b-col>
                </b-row>
                <b-row class="m-1">
                  <b-col>
                    <span class="text-muted mr-75">{{ $t('interactivities.message') }}</span>
                  </b-col>
                  <b-col>
                    <a @click="showModal()">{{ $t('see') }}</a>
                  </b-col>
                </b-row>
                <a
                  v-if="fileMail"
                  :href="fileMail"
                >{{ $t('interactivities.pdf') }}</a>
              </b-col>
              <b-col v-else-if="connect == 'PUS'">
                <h4 class="mb-2">
                  PUSH
                </h4>
                <!-- <div class="mb-1 mb-sm-3"> -->
                <b-row class="m-1">
                  <b-col>
                    <span class="text-muted mr-75">URL Push</span>
                  </b-col>
                  <b-col>
                    <span>{{ urlPush }}</span>
                  </b-col>
                </b-row>
                <b-row class="m-1">
                  <b-col>
                    <span class="text-muted mr-75">{{ $t('interactivities.message') }}</span>
                  </b-col>
                  <b-col>
                    <span>{{ messagePush }}</span>
                  </b-col>
                </b-row>
                <b-row class="m-1">
                  <b-col>
                    <span class="text-muted mr-75">{{ $t('envio') }}</span>
                  </b-col>
                  <b-col>
                    <span
                      v-if="isAuto"
                      class="text-success"
                    >{{ $t('interactivities.auto') }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >{{ $t('interactivities.noAuto') }}</span>
                  </b-col>
                </b-row>

                <!-- </div> -->
              </b-col>
              <b-col v-else-if="connect == 'ADV'">
                <h4 class="mb-2">
                  BANNER: {{ resourceNameEnd }}
                </h4>
                <div class="mb-1 mb-sm-3">
                  <img
                    v-if="imageInclude"
                    :src="bucketUrlEnd"
                    width="450"
                    height="150"
                    alt=""
                  >
                  <audio
                    v-else-if="videotypeEnd == 'AUR'"
                    :key="resourceIdEnd"
                    controls
                  >
                    <source
                      v-if="directUrlEnd != ''"
                      :src="directUrlEnd"
                      type="audio/mp3"
                    >
                    <source
                      v-else
                      :src="bucketUrlEnd"
                      type="audio/mp3"
                    >
                  </audio>
                  <video
                    v-else
                    :key="resourceIdEnd"
                    width="500"
                    height="305"
                    controls
                  >
                    <source
                      v-if="directUrlEnd != ''"
                      :src="directUrlEnd"
                      type="video/mp4"
                    >
                    <source
                      :src="bucketUrlEnd"
                      type="video/mp4"
                    >
                  </video>
                </div>
              </b-col>
              <b-col v-else>
                <b-row>
                  <b-col class="text-center">
                    <h4 class="mb-2">
                      <strong>{{ resourceNameEnd }}</strong>
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <img
                      v-if="imageInclude"
                      :src="bucketUrlEnd"
                      width="450"
                      height="150"
                      alt=""
                    >
                    <audio
                      v-else-if="videotypeEnd == 'AUR'"
                      :key="resourceIdEnd"
                      controls
                    >
                      <source
                        v-if="directUrlEnd != ''"
                        :src="directUrlEnd"
                        type="audio/mp3"
                      >
                      <source
                        v-else
                        :src="bucketUrlEnd"
                        type="audio/mp3"
                      >
                    </audio>
                    <video
                      v-else
                      :key="resourceIdEnd"
                      width="500"
                      height="305"
                      controls
                    >
                      <source
                        v-if="directUrlEnd != ''"
                        :src="directUrlEnd"
                        type="video/mp4"
                      >
                      <source
                        :src="bucketUrlEnd"
                        type="video/mp4"
                      >
                    </video>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="my-modal"
      hide-footer
      :title="$t('interactivities.bodyMess')"
    >
      <div class="d-block text-center">
        <html-modal :msg="bodyMail" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        Close Me
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BImg,
  BCardBody,
  BCardText,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'

import { getUserData } from '@/auth/utils'
import HtmlModal from '../../common/HtmlModal.vue'

export default {
  components: {
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BButton,
    BCard,
    BImg,
    HtmlModal,
  },
  props: {
    msg: Boolean,
  },
  data() {
    return {
      response: null,
      vacio: false,
      description: null,
      name: null,
      image: null,
      connect: null,
      date: null,
      time: null,
      secondFile: null,
      hour: null,
      second: null,
      fileMail: null,
      bodyMail: null,
      subjectMail: null,
      isAuto: null,
      urlPush: null,
      messagePush: null,
      typePush: null,
      content: null,
      resourceStart: null,
      resourceEnd: null,
      isVisible: null,
      isActive: null,

      videotype: null,
      resourceName: null,
      resourceId: null,
      bucketUrl: null,
      directUrl: null,

      videotypeEnd: null,
      resourceNameEnd: null,
      resourceIdEnd: null,
      bucketUrlEnd: null,
      directUrlEnd: null,
      imageInclude: false,

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    parsearHtml(html) {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(html, 'text/html')
      // console.log(htmlDoc);
      return htmlDoc
    },
    getData() {
      const { id } = this.$route.params
      const userData = getUserData()
      axios
        .post('', {
          query: `
          query{
            allInteractivities(id: "${id}",client:"${userData.profile.client.id}"){
             edges {
              node {
                  id
                  name
                  description
                  connect                          
                  image
                  
                  second                  
                  date
                  hour
                  
                  time
                  secondFile
                  
                  #Mail
                  fileMail
                  bodyMail
                  subjectMail
                  
                  
                  #PUSH                  
                  isAuto
                  urlPush
                  messagePush
                  typePush
                
                  content {
                    id
                    name
                  }
                  resourceStart {
                    id
                    name
                    videotype
                    directUrl
                    bucketUrl
                    file{
                      fileUrl
                    }
                  }
                  resourceEnd {
                    id
                    name
                    videotype
                    directUrl
                    bucketUrl
                    file{
                      fileUrl
                    }
                  }
                  isVisible
                  isActive
                
              }
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)
          const [edges] = res.data.data.allInteractivities.edges
          this.response = edges
          this.name = this.response.node.name
          this.image = this.response.node.image
          this.description = this.response.node.description
          this.connect = this.response.node.connect

          this.date = this.response.node.date
          this.time = this.response.node.time
          this.secondFile = this.response.node.secondFile
          this.hour = this.response.node.hour
          this.second = this.response.node.second

          this.fileMail = this.response.node.fileMail
          this.bodyMail = this.response.node.bodyMail
          this.subjectMail = this.response.node.subjectMail

          this.isAuto = this.response.node.isAuto
          this.urlPush = this.response.node.urlPush
          this.messagePush = this.response.node.messagePush
          this.typePush = this.response.node.typePush

          this.content = this.response.node.content
          this.resourceStart = this.response.node.resourceStart
          this.resourceEnd = this.response.node.resourceEnd
          this.isVisible = this.response.node.isVisible
          this.isActive = this.response.node.isActive

          this.resourceId = this.resourceStart.id
          this.videotype = this.resourceStart.videotype
          this.resourceName = this.resourceStart.name
          this.bucketUrl = this.resourceStart.bucketUrl
          this.directUrl = this.resourceStart.directUrl
          try {
            this.resourceIdEnd = this.resourceEnd.id
            this.videotypeEnd = this.resourceEnd.videotype
            this.resourceNameEnd = this.resourceEnd.name
            this.bucketUrlEnd = this.resourceEnd.bucketUrl
            this.directUrlEnd = this.resourceEnd.directUrl
            if (this.bucketUrlEnd.includes('jpg')) this.imageInclude = true
            else this.imageInclude = false
          // eslint-disable-next-line no-empty
          } catch (error) { }
        })
    },

    goToBackPage() {
      const { content } = this.$route.params
      this.$router.push({ name: 'contents-view', params: { id: content } })
    },
    editInteractivitie() {
      this.$router.push({
        name: 'interactivities-edit',
        params: {
          id: this.$route.params.id,
          content: this.$route.params.content,
        },
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

form ::v-deep {
  // Mail To vue-select style

  .message-editor {
    min-height: 250px;
    min-width: -webkit-fill-available;
    padding: 20px;
    color: white;
  }

  // Quill Editor Style
  .quill-editor {
    min-height: 250px;
    color: white;
    border: 1px solid #3b4253;

    .ql-container.ql-snow {
      border: 0;
    }
  }
}
</style>
