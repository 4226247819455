<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-html="msg" />
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
}
</script>
